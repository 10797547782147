import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { formatDistanceStrict } from "date-fns";
import Photos from "./Photos";
import Options from "../../ui/options/Options";
import ReactMarkdown from "react-markdown";
import NotFound from "../minor/NotFound";
import currentDrop from "../../currentDrop";

const Page = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 60px;
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;

const RightSide = styled.div`
  margin-left: 40px;
  max-width: 500px;
  a {
    color: #157dd1;
  }
  @media screen and (max-width: 600px) {
    margin-top: 40px;
    margin-left: 0;
  }
`;

const ProductTitle = styled.h2`
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 0;
  margin-bottom: 0;
`;

const Price = styled.h4`
  font-size: 24px;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.6);
`;

const OptionsSection = styled.div`
  margin-top: 40px;
`;

const OptionsTitle = styled.h4`
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
`;

const AddButton = styled.button`
  cursor: pointer;
  padding: 10px 20px;
  font-weight: 700;
  color: white;
  background: #157dd1;
  border: none;
  margin-top: 30px;
  font-size: 16px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  :hover {
    background: #0064b8;
  }
`;

const Divider = styled.div`
  margin: 40px auto;
  height: 2px;
  width: calc(100% - 50px);
  background: rgba(0, 0, 0, 0.1);
`;

const InDrop = styled.div`
  color: rgba(0, 0, 0, 0.4);
  font-weight: 700;
  a {
    color: rgba(0, 0, 0, 0.6);
  }
`;

const DropRemaining = styled.div`
  margin-top: 10px;
  font-weight: 700;
`;

const Product = (props) => {
  let [cart, setCart] = useGlobal("cart");
  let [baseData] = useGlobal("baseData");
  let [, setCartOpen] = useGlobal("cartOpen");
  let product,
    initialOptions = {};
  try {
    product = baseData.products.filter(
      (i) => String(i.id) === props.match.params.id
    )[0];
    if (product.options)
      product.options.forEach((variant) => {
        initialOptions[variant.name] = variant.options[0];
      });
  } catch (err) {
    console.error(err);
    product = null;
  }

  let [optionsActive, setOptionsActive] = useState(initialOptions);
  let exists = false;
  if (product) exists = true;
  if (exists && product.inDrop && currentDrop.endDate < new Date())
    exists = false;

  return exists ? (
    <Page>
      <Photos photos={product.photos} />
      <RightSide>
        <ProductTitle>{product.name}</ProductTitle>
        <Price>${product.price}</Price>
        {product.inDrop && (
          <InDrop>
            FROM THE <Link to="/drop">{currentDrop.title}</Link> MERCH DROP
          </InDrop>
        )}
        {product.inDrop && (
          <DropRemaining>
            {formatDistanceStrict(
              new Date(),
              currentDrop.endDate
            ).toUpperCase()}{" "}
            REMAINING
          </DropRemaining>
        )}
        {product.options && (
          <OptionsSection>
            {product.options.map((variant) => (
              <div key={variant.name}>
                <OptionsTitle>{variant.name.toUpperCase()}</OptionsTitle>
                <Options
                  options={variant.options}
                  active={optionsActive[variant.name]}
                  setActive={(active) =>
                    setOptionsActive({
                      ...optionsActive,
                      [variant.name]: active,
                    })
                  }
                />
              </div>
            ))}
          </OptionsSection>
        )}
        <AddButton
          onClick={() => {
            setCart([
              ...cart,
              {
                id: product.id,
                options: optionsActive,
              },
            ]);
            setCartOpen(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="white"
              d="M17 16a3 3 0 1 1-2.83 2H9.83a3 3 0 1 1-5.62-.1A3 3 0 0 1 5 12V4H3a1 1 0 1 1 0-2h3a1 1 0 0 1 1 1v1h14a1 1 0 0 1 .9 1.45l-4 8a1 1 0 0 1-.9.55H5a1 1 0 0 0 0 2h12zM7 12h9.38l3-6H7v6zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            />
          </svg>
          ADD TO CART
        </AddButton>
        <Divider />
        <ReactMarkdown source={product.description} />
      </RightSide>
    </Page>
  ) : (
    <NotFound />
  );
};

export default Product;
