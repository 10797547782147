import React from "react";
import styled from "styled-components";

const Page = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 60px;
  h3 {
    color: #444;
    margin-top: 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

function Privacy() {
  return (
    <Page>
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy explains how we (artbyFORM Inc.) collect and use
        your information on this site. We'll keep it short and easy to
        understand! By using this site, you agree to the terms below.
      </p>
      <h3>What information we collect</h3>
      <p>
        We may use analytics software to automatically collect data about users
        visiting this site. This data is automatically collected about your
        browser, IP address, and your usage of the site, but is not personal in
        nature.
      </p>
      <p>
        When you place an order on this shop, we'll collect certain data in
        order to fulfill your order. This may include, for example, your name,
        email address, shipping address, and payment information. We only
        collect the data necessary to successfully fulfill your order, and all
        of this data is provided directly to us by you when you check out.
      </p>
      <h3>How we use your information</h3>
      <p>
        Metadata and aggregate information about how you use this site may be
        used internally for business analysis purposes and to improve this site
        and our products. Specific data about you will be used in order to place
        and fulfill product orders, contact you about your order, and
        potentially to respond to customer service requests and other business
        purposes relating to your order as necessary. We may also share your
        data if legally required to due to law enforcement action.
      </p>
      <h3>Who your information is shared with</h3>
      <p>
        Your data will be shared with third-party service providers who work
        with us to perform such activities as creating and fulfilling product
        orders, website analytics, payment processing, and email sending. We may
        also share your data as necessary to respond to legal process, or if we
        or part of our business are acquired by an outside entity.
      </p>
      <h3>How we secure your information</h3>
      <p>
        We take efforts to ensure that unauthorized third parties will be unable
        to access your personal information. However, it is always possible that
        your information could be compromised, and we cannot guarantee its
        security. To protect your payment details, we never directly handle
        credit card information and instead work with Stripe to ensure payments
        are handled safely and securely.
      </p>
      <h3>General details</h3>
      <p>
        We reserve the right to change this policy at any time. The latest
        version can always be viewed at this page. If you have questions or
        concerns, contact us at noco@artbyform.com.
      </p>
    </Page>
  );
}

export default Privacy;
