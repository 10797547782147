import React from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import CartModal from "./Cart/Cart";
import { Link } from "react-router-dom";
import { Elements } from "react-stripe-elements";

const HeaderBox = styled.div`
  margin: 40px auto;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

const Logo = styled.img`
  width: 200px;
  margin-left: -8px;
`;

const Cart = styled.div`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  margin-right: -20px;
`;

const NumCircle = styled.div`
  width: 28px;
  height: 28px;
  background: #157dd1;
  font-weight: 700;
  font-family: "Montserrat";
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 12px;
  top: -28px;
  border-radius: 100px;
  border: 5px solid white;
  opacity: ${props => props.opacity};
`;

function Header() {
  let [cart] = useGlobal("cart");
  let [cartOpen, setCartOpen] = useGlobal("cartOpen", false);

  return (
    <HeaderBox>
      <Link to="/">
        <Logo src="/logo.png" />
      </Link>
      <Cart onClick={() => setCartOpen(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path d="M17 16a3 3 0 1 1-2.83 2H9.83a3 3 0 1 1-5.62-.1A3 3 0 0 1 5 12V4H3a1 1 0 1 1 0-2h3a1 1 0 0 1 1 1v1h14a1 1 0 0 1 .9 1.45l-4 8a1 1 0 0 1-.9.55H5a1 1 0 0 0 0 2h12zM7 12h9.38l3-6H7v6zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
        </svg>
        <NumCircle opacity={cart.length === 0 ? 0 : 1}>{cart.length}</NumCircle>
      </Cart>
      {cartOpen && (
        <Elements
          fonts={[
            {
              cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:500"
            }
          ]}
        >
          <CartModal close={() => setCartOpen(false)} />
        </Elements>
      )}
    </HeaderBox>
  );
}

export default Header;
