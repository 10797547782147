import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CardBase = styled(Link)`
  text-decoration: none;
`;

const ProductImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
`;

const Title = styled.h4`
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.85);
`;

const Price = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  font-size: 12px;
`;

function ProductCard(props) {
  return (
    <CardBase to={`/product/${props.product.id}`}>
      <div>
        <ProductImage src={props.product.photo} />
        <Title>{props.product.title.toUpperCase()}</Title>
        <Price>${props.product.price}</Price>
      </div>
    </CardBase>
  );
}

export default ProductCard;
