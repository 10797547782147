const drop = {
  startDate: new Date(
    "Fri Nov 5 2021 14:00:00 GMT-0400 (Eastern Daylight Time)"
  ),
  endDate: new Date(
    "Fri Dec 31 2021 23:59:59 GMT-0500 (Eastern Standard Time)"
  ),
  title: "NORTH STAR DREAM",
  featureImage: "/drop-feature2.jpg",
  backgroundImage: "/drop-background2.jpg",
};

export default drop;
