import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import ProductCard from "../../ui/productCard/ProductCard";
import currentDrop from "../../currentDrop";

const Page = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 60px;
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

const ProductsSection = styled.div`
  margin: 60px 0;
`;

const ProductGrid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const DropCard = styled.div`
  height: 250px;
  width: calc(100% + 20px);
  margin-left: -10px;
  border-radius: 4px;
  background-size: cover;
  display: flex;
  align-items: center;
`;

const DropFeatureImg = styled.img`
  border-radius: 4px;
  height: 230px;
  width: 230px;
  margin-left: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const DropText = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
`;

const DropTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 10px;
  margin-top: 0;
`;

const DropSubtitle = styled.div`
  strong {
    margin-left: 2px;
  }
`;

function toDDHHMMSS(tstr) {
  var sec_num = parseInt(tstr, 10); // don't forget the second param
  var days = Math.floor(sec_num / 86400);
  var hours = Math.floor((sec_num - days * 86400) / 3600);
  var minutes = Math.floor((sec_num - days * 86400 - hours * 3600) / 60);
  var seconds = sec_num - days * 86400 - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return days + ":" + hours + ":" + minutes + ":" + seconds;
}

function getTime(end) {
  let tstr = "" + (end - Date.now()) / 1000;
  return toDDHHMMSS(tstr);
}

function Home() {
  let [baseData] = useGlobal("baseData");
  let [tstr, setTstr] = useState(getTime(currentDrop.endDate));

  useEffect(() => {
    const a = setInterval(() => setTstr(getTime(currentDrop.endDate)), 1000);
    return () => clearInterval(a);
  }, []);

  if (currentDrop.startDate < new Date() && currentDrop.endDate > new Date())
    return (
      <Page>
        <DropCard>
          <DropFeatureImg src={currentDrop.featureImage} />
          <DropText>
            <div>
              <DropTitle>{currentDrop.title}</DropTitle>
              <DropSubtitle>MERCH DROP</DropSubtitle>
            </div>
            <div>
              <div>
                Designed by{" "}
                <a
                  href="https://twitter.com/nesulosity"
                  style={{ color: "#157dd1" }}
                >
                  Nesulosity
                </a>
              </div>
              <br />
              <strong>
                {tstr + " "}
                REMAINING
              </strong>
            </div>
          </DropText>
        </DropCard>
        <ProductsSection>
          <ProductGrid>
            {baseData.products
              .filter((product) => product.inDrop)
              .map((i) => (
                <ProductCard
                  key={i.id}
                  product={{
                    id: i.id,
                    photo: i.photos[0],
                    title: i.name,
                    price: i.price,
                  }}
                />
              ))}
          </ProductGrid>
        </ProductsSection>
      </Page>
    );
  else
    return (
      <Page>
        <h2>There is not currently a live merch drop.</h2>
      </Page>
    );
}

export default Home;
