export default (cart, baseData, shippingPrice, taxAmount) => {
  let total = 0;
  cart.forEach((product) => {
    const globalProduct = baseData.products.filter(
      (i) => i.id === product.id
    )[0];
    const price = globalProduct.price.replace(".", "") * 1;
    total += price;
  });
  if (shippingPrice) total += shippingPrice.replace(".", "") * 1;
  if (taxAmount) total += taxAmount;
  return total / 100;
};
