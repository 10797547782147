import React from "react";
import styled from "styled-components";

const Page = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 60px;
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

function NotFound() {
  return (
    <Page>
      <h1>404</h1>
      <h3>Page not found</h3>
    </Page>
  );
}

export default NotFound;
