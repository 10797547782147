// Import extermal deps
import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import apiRoot from "./apiRoot.js";
import { Route as BaseRoute, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";
// Import components and pages
import Header from "./app/sitewide/Header";
import Footer from "./app/sitewide/Footer";
import Terms from "./app/legal/Terms";
import Privacy from "./app/legal/Privacy";
import NotFound from "./app/minor/NotFound";
import Home from "./app/homepage/Home";
import Drop from "./app/homepage/Drop";
import Product from "./app/productPage/Product";

// Error
const Err = styled.h3`
  color: #fa3421;
  max-width: 1000px;
  margin: 0 auto;
`;

// Redefine Route to scroll to top on page change
class ScrollToTopRoute extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <BaseRoute {...rest} render={(props) => <Component {...props} />} />;
  }
}

const Route = withRouter(ScrollToTopRoute);

function App() {
  let [baseData, setBaseData] = useGlobal("baseData");
  let [error, setError] = useGlobal("error");

  useEffect(() => {
    async function effect() {
      if (!baseData) {
        try {
          setBaseData({
            loading: false,
            ...(await (await fetch(apiRoot)).json()),
          });
        } catch (err) {
          setError(err.message);
        }
      }
    }
    effect();
  });

  return (
    <div>
      <Header />
      {error && <Err>{error}</Err>}
      {baseData && !baseData.loading && (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/drop" component={Drop} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/product/:id" component={Product} />
          <Route component={NotFound} />
        </Switch>
      )}
      <Footer />
      {baseData && baseData.loading && <h3>Loading...</h3>}
    </div>
  );
}

export default App;
