import React from "react";
import styled from "styled-components";

const ShippingMethodChoice = styled.div`
  padding: 15px;
  border: 1px solid ${props => (props.active ? "hsl(205, 75%, 55%)" : "#bbb")};
  background: ${props => (props.active ? "hsl(195, 85%, 96%)" : "transparent")};
  color: ${props => (props.active ? "hsl(205, 55%, 30%)" : "#555")};
  border-radius: 4px;
  cursor: pointer;
  h3 {
    margin-top: 0;
    margin-bottom: 15px;
  }
`;

const Divider = styled.div`
  margin-bottom: 15px;
`;

const Rate = styled.div`
  color: ${props => (props.active ? "hsl(205, 85%, 35%)" : "#777")};
`;

function ShippingMethod(props) {
  return (
    <div>
      {props.shippingMethods.map((i, index) => (
        <div key={i.id}>
          <ShippingMethodChoice
            onClick={() => props.setShippingMethod(i.id)}
            active={props.shippingMethod === i.id}
          >
            <h3>{i.name}</h3>
            <Rate active={props.shippingMethod === i.id}>${i.rate}</Rate>
          </ShippingMethodChoice>
          {index !== props.shippingMethods.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
}

export default ShippingMethod;
