import React from "react";
import Option from "./Option";

const Options = props => {
  return (
    <div>
      {props.options.map(i => (
        <Option
          active={props.active === i}
          setActive={() => props.setActive(i)}
          name={i}
        />
      ))}
    </div>
  );
};

export default Options;
