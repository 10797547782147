import React from "react";
import styled from "styled-components";

const CenterText = styled.div`
  margin: 40px auto;
  text-align: center;
  h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {
    color: #555;
  }
`;

function Complete() {
  return (
    <CenterText>
      <h3>Your purchase is complete!</h3>
      <p>
        Thanks for buying merch from FORM! Your support will allow us to do
        bigger and better things in the future and to continue supporting our
        community. If you have any issues or questions regarding your order,
        just send us an email at <strong>noco@artbyform.com</strong>!
      </p>
    </CenterText>
  );
}

export default Complete;
