import React from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import getTotal from "./getTotal";

const Container = styled.div``;

const Container2 = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const Product = styled.div`
  display: flex;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  justify-content: space-between;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
`;

const Flex = styled.div`
  display: flex;
`;

const Title = styled.h3`
  margin: 10px 0;
  font-weight: 500;
  margin-left: 20px;
`;

const Options = styled.p`
  font-size: 14px;
  margin: 0 0 0 20px;
  color: #555;
`;

const Price = styled.div`
  font-weight: 500;
  margin: 10px 0 5px 0;
`;

const Remove = styled.div`
  cursor: pointer;
  color: #555;
  font-size: 12px;
`;

const TotalDesc = styled.span`
  font-weight: 700;
  letter-spacing: 0.4px;
  color: #888;
  font-size: 12px;
`;

const Total = styled.div`
  font-weight: 700;
  font-size: 28px;
`;

const CenterText = styled.div`
  margin: 40px auto;
  text-align: center;
  h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {
    color: #555;
  }
`;

function ProductList() {
  let [cart, setCart] = useGlobal("cart");
  let [baseData] = useGlobal("baseData");

  let total = getTotal(cart, baseData).toLocaleString("US", {
    style: "currency",
    currency: "USD"
  });

  return (
    <Container>
      {cart.length === 0 && (
        <CenterText>
          <h3>Your cart is empty!</h3>
          <p>
            Click the "Add to Cart" button on any item's page to get started.
          </p>
        </CenterText>
      )}
      <div>
        {cart.map((product, index) => {
          const globalProduct = baseData.products.filter(
            i => i.id === product.id
          )[0];
          return (
            <Product key={index}>
              <Flex>
                <Image src={globalProduct.photos[0]} />
                <div>
                  <Title>{globalProduct.name}</Title>
                  <Options>
                    {globalProduct.options && globalProduct.options.length > 0
                      ? globalProduct.options.reduce(
                          (a, c) =>
                            a
                              ? a + " - " + product.options[c.name]
                              : product.options[c.name],
                          ""
                        )
                      : ""}
                  </Options>
                </div>
              </Flex>
              <div>
                <Price>${globalProduct.price}</Price>
                <Remove
                  onClick={() => setCart(cart.filter((a, b) => b !== index))}
                >
                  Remove
                </Remove>
              </div>
            </Product>
          );
        })}
        {cart.length > 0 && (
          <Container2>
            <TotalDesc>YOUR TOTAL</TotalDesc>
            <Total>{total}</Total>
          </Container2>
        )}
      </div>
    </Container>
  );
}

export default ProductList;
