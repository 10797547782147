import React, { useState } from "react";
import styled from "styled-components";

const Base = styled.div`
  max-width: 500px;
`;

const Photo = styled.img`
  max-width: 100%;
`;

const Selector = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`;

const SelectPhoto = styled.div`
  cursor: pointer;
  width: 80px;
  height: 80px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid ${props => (props.active ? "black" : "transparent")};
`;

const SelectPhotoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Photos = props => {
  const [photo, setPhoto] = useState(props.photos[0]);

  return (
    <Base>
      <Photo src={photo} />
      {props.photos.length > 1 && (
        <Selector>
          {props.photos.map(selectPhoto => (
            <SelectPhoto
              active={photo === selectPhoto}
              onClick={() => setPhoto(selectPhoto)}
            >
              <SelectPhotoImg src={selectPhoto} />
            </SelectPhoto>
          ))}
        </Selector>
      )}
    </Base>
  );
};

export default Photos;
