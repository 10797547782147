import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { formatDistanceStrict } from "date-fns";
import ProductCard from "../../ui/productCard/ProductCard";
import currentDrop from "../../currentDrop";

const Page = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 60px;
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

const AboutUs = styled.div`
  margin: 60px 0;
  max-width: 800px;
  font-weight: 500;
  p {
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.5;
  }
`;

const ProductsSection = styled.div`
  margin: 60px 0;
`;

const FollowUs = styled.div`
  margin: 60px 0;
  font-weight: 700;
  line-height: 1.5;
  @media screen and (max-width: 600px) {
    margin: 20px 0;
  }
`;

const GeneralLink = styled.a`
  color: rgba(0, 0, 0, 0.5);
  padding: 4px 0;
  margin-right: 15px;
  text-decoration: none;
  display: inline-block;
  :hover {
    color: rgba(0, 0, 0, 0.85);
  }
`;

const BigLink = styled(GeneralLink)`
  display: block;
  font-size: 20px;
  margin-bottom: 20px;
  text-decoration: underline;
`;

const Bobosmile = styled.img`
  width: 48px;
  height: 48px;
`;

const ProductGrid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SeparatedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h2 {
    margin: 0;
  }
`;

const Option = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => (props.active ? "#333" : "#999")};
`;

const Separator = styled.div`
  margin-right: 10px;
`;

const Flex = styled.div`
  display: flex;
`;

const DropCard = styled.div`
  height: 250px;
  width: calc(100% + 20px);
  margin-left: -10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("${(props) => props.background}");
  background-size: cover;
  display: flex;
  align-items: center;
  margin-top: -30px;
`;

const DropFeatureImg = styled.img`
  border-radius: 4px;
  height: 230px;
  width: 230px;
  margin-left: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const DropText = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  color: white;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
`;

const DropTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 10px;
  margin-top: 0;
`;

const DropSubtitle = styled.div`
  strong {
    margin-left: 2px;
  }
`;

const DropLink = styled(Link)`
  color: white;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 0.3px;
  svg {
    margin-left: 10px;
    margin-top: -2px;
  }
`;

function Home() {
  let [baseData] = useGlobal("baseData");
  let [active, setActive] = useState("all");

  return (
    <Page>
      <AboutUs>
        <h2>Hi. We're FORM.</h2>
        <p>
          As a way to provide funding for bigger and better things, as well as
          cover some regular expenses involved with running a community of this
          size, we've decided to open a merch shop!
        </p>
        <p>
          It's pretty bare in here right now, but we've got some pretty neato
          stuff to get things started, and we've got more stuff on the way!
          Check it out below.
        </p>
        <p>
          <Bobosmile src="/bobosmile.jpg" />
        </p>
        <p> - The FORM Team</p>
      </AboutUs>
      {currentDrop.startDate < new Date() && currentDrop.endDate > new Date() && (
        <DropCard background={currentDrop.backgroundImage}>
          <DropFeatureImg src={currentDrop.featureImage} />
          <DropText>
            <div>
              <DropTitle>{currentDrop.title}</DropTitle>
              <DropSubtitle>
                MERCH DROP —{" "}
                <strong>
                  {formatDistanceStrict(
                    new Date(),
                    currentDrop.endDate
                  ).toUpperCase()}{" "}
                  REMAINING
                </strong>
              </DropSubtitle>
            </div>
            <DropLink to="/drop">
              VIEW DROP{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="white"
                  d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
                />
              </svg>
            </DropLink>
          </DropText>
        </DropCard>
      )}
      <ProductsSection>
        <SeparatedContainer>
          <h2>Products</h2>
          <Flex>
            <Option active={active === "all"} onClick={() => setActive("all")}>
              ALL
            </Option>
            <Separator />
            <Option
              active={active === "tops"}
              onClick={() => setActive("tops")}
            >
              TOPS
            </Option>
            <Separator />
            <Option
              active={active === "hats"}
              onClick={() => setActive("hats")}
            >
              HATS
            </Option>
            <Separator />
            <Option active={active === "etc"} onClick={() => setActive("etc")}>
              ETC
            </Option>
          </Flex>
        </SeparatedContainer>
        <ProductGrid>
          {baseData.products
            .filter((product) => {
              let filterIn = true;
              if (active !== "all") filterIn = product.category === active;
              if (
                currentDrop.startDate > new Date() ||
                (currentDrop.endDate < new Date() && filterIn)
              )
                filterIn = !product.inDrop;
              return filterIn;
            })
            .map((i) => (
              <ProductCard
                key={i.id}
                product={{
                  id: i.id,
                  photo: i.photos[0],
                  title: i.name,
                  price: i.price,
                }}
              />
            ))}
        </ProductGrid>
      </ProductsSection>
      <FollowUs>
        <h2>Follow FORM:</h2>
        <BigLink href="https://discord.gg/7k2atE5">Join us on Discord</BigLink>
        <GeneralLink href="https://twitter.com/artbyform">Twitter</GeneralLink>
        <GeneralLink href="https://instagram.com/artbyform">
          Instagram
        </GeneralLink>
        <GeneralLink href="https://soundcloud.com/artbyform">
          Soundcloud
        </GeneralLink>
        <GeneralLink href="https://artbyform.bandcamp.com">
          Bandcamp
        </GeneralLink>
        <GeneralLink href="https://artbyform.com">Official Website</GeneralLink>
      </FollowUs>
    </Page>
  );
}

export default Home;
