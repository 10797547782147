import React from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import getTotal from "./getTotal";

const CenterText = styled.div`
  margin: 40px auto;
  text-align: center;
  h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {
    color: #555;
  }
  small {
    color: #888;
  }
`;

function SalesTax(props) {
  let [cart] = useGlobal("cart");
  let [baseData] = useGlobal("baseData");

  const total = getTotal(cart, baseData, props.shippingMethodPrice);

  return (
    <div>
      <CenterText>
        <h3>We've added tax to your order</h3>
        <p>
          Our fulfillment provider charges sales tax or VAT based on your
          location. We've passed along the tax of{" "}
          <strong>
            {(props.taxAmount / 100).toLocaleString("US", {
              style: "currency",
              currency: "USD",
            })}
          </strong>{" "}
          to you, bringing your total to{" "}
          <strong>
            {(total + props.taxAmount / 100).toLocaleString("US", {
              style: "currency",
              currency: "USD",
            })}
          </strong>
          .
        </p>
        <small>
          If this seems incorrect, go back to correct your shipping address.
        </small>
      </CenterText>
    </div>
  );
}

export default SalesTax;
