import React from "react";
import styled from "styled-components";

const Page = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 60px;
  h3 {
    color: #444;
  }
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

function Terms() {
  return (
    <Page>
      <h1>Terms of service</h1>
      <p>
        These Terms of Service govern your use of this site. By using this site,
        you agree to these terms, and they constitute a binding legal agreement
        between you, the user, and us, artbyFORM Inc.
      </p>
      <h3>General terms</h3>
      <p>
        To use this site, you must be at least the age of majority within your
        jurisdiction or that you are at least 13 years of age and have
        permission to use this site from your legal parent or guardian, who also
        agrees to these terms. In your use of this service and our products, you
        must not violate any laws in your jurisdiction. You also agree not to
        reproduce, copy, or sell any portion of this site or our products
        without our express written permission, to otherwise infringe upon our
        or others' intellectual property rights, to attempt to disrupt the
        operation of our site or other websites in any way, or to automate use
        of the site's features.
      </p>
      <h3>Products</h3>
      <p>
        We reserve the right to change or discontinue this site, any of our
        products, and their prices at any time, with or without notice. All
        products are displayed as accurately as possible but these digital
        reproductions and their colors and appearances are not guaranteed to
        match the physical product exactly. We also reserve the right to limit
        sales of our products to anyone or any specific region. All products and
        this site are provided with no warranty or other guarantee, and our and
        our related entities disclaim all liability to the maximum extent
        permitted by law.
      </p>
      <h3>Refunds</h3>
      <p>
        From time of purchase, you have 30 days to request a return. Returns are
        only accepted if the order arrives damaged, or the incorrect order was
        sent. Please double-check our sizing charts/information to make sure you
        are ordering the correct size. To be eligible for a return, your item
        must be unused and in the same condition that you received it. It must
        also be in the original packaging. Please contact us at
        noco@artbyform.com to request further information, but be aware that
        returns are only available on a case-by-case basis.
      </p>
      <h3>This Agreement</h3>
      <p>
        If any part of these Terms of Service is determined to be unlawful, void
        or unenforceable, such provision shall nonetheless be enforceable to the
        fullest extent permitted by applicable law, and the unenforceable
        portion shall be deemed to be severed from these Terms of Service. Such
        determination shall not affect the validity and enforceability of any
        other remaining provisions. These Terms are effective until terminated
        by either you or us. You can terminate the terms by discontinuing use of
        these services. The failure of us to exercise or enforce any right or
        provision of these Terms of Service shall not constitute a waiver of
        such right or provision. These Terms of Service and any policies or
        operating rules posted by us on this site or in respect to The Service
        constitutes the entire agreement and understanding between you and us
        and govern your use of the Service, superseding any prior or
        contemporaneous agreements, communications and proposals, whether oral
        or written, between you and us (including, but not limited to, any prior
        versions of the Terms of Service). Any ambiguities in the interpretation
        of these Terms of Service shall not be construed against the drafting
        party. These Terms of Service and any separate agreements whereby we
        provide you Services shall be governed by and construed in accordance
        with the laws of United States and the State of Washington.
      </p>
      <h3>Contact Information</h3>
      <p>You can contact us by email at noco@artbyform.com.</p>
    </Page>
  );
}

export default Terms;
