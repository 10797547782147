import React from "react";
import styled from "styled-components";

const OptionBox = styled.div`
  border: 2px solid ${props => (props.active ? "#157dd1" : "#bbb")};
  padding: 6px 12px;
  color: ${props => (props.active ? "white" : "#888")};
  background: ${props => (props.active ? "#157dd1" : "transparent")};
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  :hover {
    border: 2px solid ${props => (props.active ? "#157dd1" : "#666")};
    color: ${props => (props.active ? "white" : "#666")};
    background: ${props => (props.active ? "#157dd1" : "transparent")};
  }
`;

const Option = props => {
  return (
    <OptionBox onClick={props.setActive} active={props.active}>
      {props.name}
    </OptionBox>
  );
};

export default Option;
