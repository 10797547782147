import React, { useState } from "react";
import styled from "styled-components";
import Input from "../Input";

const Outside = styled.div`
  position: relative;
  flex: 1;
  z-index: 10;
`;

const BaseComponent = styled.div`
  padding: 3px 6px 6px 6px;
  box-shadow: ${(props) =>
    props.shadow ? "0 0 4px 1px rgba(0, 0, 0, 0.15)" : "none"};
  margin-top: -3px;
  margin-left: -6px;
  border-radius: 6px;
  z-index: 10;
  position: absolute;
  width: 100%;
  background: white;
  transition: box-shadow 0.1s;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: #888;
  margin-bottom: 3px;
`;

const List = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  max-height: 200px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 12px;
    height: 18px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    height: 12px;
    border: 8px solid transparent;
    border-width: 0 0 0 8px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Item = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #555;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 2px;
  z-index: 15;
  :hover {
    background: hsl(195, 84%, 93%);
    color: hsl(205, 35%, 30%);
  }
`;

function Base(props) {
  const [input, setInput] = useState(props.value);
  const regex = new RegExp(`^(${input})`, "i");
  const [active, setActive] = useState(false);
  return (
    <Outside>
      <BaseComponent shadow={active}>
        <Label>{props.label}</Label>
        <Input
          autoComplete="none"
          value={input}
          onChange={(x) => setInput(x.target.value)}
          onFocus={() => {
            setActive(true);
            setInput("");
          }}
          onBlur={() => {
            if (!active) return;
            setActive(false);
            setInput(props.value);
          }}
        />
        <List visible={active && input}>
          {props.items
            .filter((option) => regex.test(option.name))
            .map((i) => (
              <Item
                key={i.code}
                onMouseDown={() => {
                  props.onChange({ target: { value: i.name } });
                  setInput(i.name);
                  setActive(false);
                }}
              >
                {i.name}
              </Item>
            ))}
        </List>
      </BaseComponent>
    </Outside>
  );
}

export default Base;
