import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CardElement } from "react-stripe-elements";
import Input from "../../../ui/Input";

const PaymentFlex = styled.div`
  display: flex;
  height: 40px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    height: 90px;
  }
`;

const StripeButton = styled.div`
  height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.07);
  color: #444;
  margin-right: 15px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  svg {
    margin-right: 10px;
  }
  :hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
    margin-right: 0;
  }
`;

const PPB = styled.div`
  flex: 1;
  display: flex;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: #888;
  margin-bottom: 3px;
`;

const Small = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #888;
  margin-top: 15px;
`;

const CardElementOutline = styled.div`
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: block;
  width: calc(100% - 20px);
  margin-bottom: 10px;
  margin-top: 2px;
`;

const Flex = styled.div`
  display: flex;
`;

const Box = styled.div`
  flex: 1;
`;

const Divider = styled.div`
  margin-right: 15px;
`;

function Checkout(props) {
  const [stripe, setStripe] = useState(false);

  useEffect(() => {
    if (!stripe)
      window.paypal
        .Buttons({
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: props.total,
                  },
                  shipping: {
                    address: {
                      address_line_1: props.address.street1,
                      address_line_2: props.address.street2,
                      admin_area_2: props.address.city,
                      admin_area_1:
                        ["United States", "Canada"].includes(
                          props.address.country
                        ) && props.address.state
                          ? props.countries
                              .filter(
                                (i) => i.name === props.address.country
                              )[0]
                              .states.filter(
                                (i) => i.name === props.address.state
                              )[0].code
                          : null,
                      country_code: props.countries.filter(
                        (i) => i.name === props.address.country
                      )[0].code,
                      postal_code: props.address.zip,
                    },
                  },
                },
              ],
              application_context: {
                shipping_preference: "SET_PROVIDED_ADDRESS",
              },
            });
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(function (details) {
              props.handlePaypal(
                details.payer.name.given_name +
                  " " +
                  details.payer.name.surname,
                details.payer.email_address,
                data.orderID
              );
            });
          },
          style: {
            layout: "horizontal",
            color: "blue",
            tagline: false,
            height: 40,
          },
        })
        .render("#paypalbuttons");
  }, []);

  return (
    <div>
      {!stripe && (
        <PaymentFlex>
          <StripeButton
            onClick={() => {
              setStripe(true);
              props.selectStripe();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="#777"
                d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm13 12a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2zm-6 0a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2z"
              />
              <rect width="20" height="4" x="2" y="7" fill="#ccc" />
            </svg>
            Pay with credit card
          </StripeButton>
          <PPB id="paypalbuttons" />
        </PaymentFlex>
      )}
      {stripe && (
        <div>
          <Flex>
            <Box>
              <Label>Full name</Label>
              <Input
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
              />
            </Box>
            <Divider />
            <Box>
              <Label>Email address</Label>
              <Input
                value={props.email}
                onChange={(e) => props.setEmail(e.target.value)}
              />
            </Box>
          </Flex>
          <Label>Card details</Label>
          <CardElementOutline>
            <CardElement
              style={{
                base: {
                  fontSize: "14px",
                  fontFamily: '"Montserrat", sans-serif',
                  color: "#555",
                },
              }}
            />
          </CardElementOutline>
          <Small>
            Your credit card details are secure and always transmitted encrypted
            over HTTPS. We never store your card details.
          </Small>
        </div>
      )}
    </div>
  );
}

export default Checkout;
