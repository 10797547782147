import styled from "styled-components";

const Input = styled.input`
  padding: 8px;
  font-weight: 500;
  color: #555;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  display: block;
  width: calc(100% - 20px);
  margin-bottom: 10px;
  margin-top: 2px;
  font-family: "Montserrat";
  :focus {
    border-color: #aaa;
    outline: none;
  }
`;

export default Input;
