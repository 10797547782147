import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const FooterBox = styled.div`
  margin: 40px auto;
  padding: 0 60px;
  max-width: 1000px;
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

const FooterLink = styled(Link)`
  font-weight: 700;
  color: #777;
  padding: 5px 0;
  margin-right: 20px;
  text-decoration: none;
`;

const FooterText = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: #aaa;
  margin-top: 15px;
`;

function Footer() {
  return (
    <FooterBox>
      <FooterLink to="/terms">Terms of Service</FooterLink>
      <FooterLink to="/privacy">Privacy Policy</FooterLink>
      <FooterText>&copy; 2023 artbyFORM Inc.</FooterText>
    </FooterBox>
  );
}

export default Footer;
