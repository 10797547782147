import React from "react";
import ReactDOM from "react-dom";
import { setGlobal } from "reactn";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import stripeKey from "./stripeKey";

setGlobal({ cart: [] });

ReactDOM.render(
  <StripeProvider apiKey={stripeKey}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StripeProvider>,
  document.getElementById("root")
);
