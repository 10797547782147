import React, { useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import apiRoot from "../../../apiRoot";
import onClickOutside from "react-onclickoutside";
import ProductList from "./ProductList";
import ShippingAddress from "./ShippingAddress";
import ShippingMethod from "./ShippingMethod";
import SalesTax from "./SalesTax";
import Checkout from "./Checkout";
import getTotal from "./getTotal";
import { injectStripe } from "react-stripe-elements";
import Complete from "./Complete";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`;

const MainBox = styled.div`
  border-radius: 12px;
  background: white;
  padding: 30px 40px 100px 40px;
  width: 600px;
  max-width: 80vw;
  position: relative;

  @media screen and (max-width: 600px) {
    padding: 15px 20px 80px 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 95px);
    max-width: calc(100vw - 40px);
    position: fixed;
    border-radius: 0;
  }
`;

const Page = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  ${(props) => props.visible && "overflow: visible;"}
  ::-webkit-scrollbar {
    width: 12px;
    height: 18px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    height: 12px;
    border: 8px solid transparent;
    border-width: 0 0 0 8px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 28px;
`;

const Close = styled.div`
  cursor: pointer;
`;

const Footer = styled.div`
  box-shadow: -1px 0 4px 1px rgba(0, 0, 0, 0.1);
  padding: 15px 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
    width: calc(100% - 40px);
  }
`;

const Button = styled.button`
  cursor: pointer;
  padding: 10px 20px;
  font-weight: 700;
  color: white;
  background: #157dd1;
  border: none;
  font-size: 16px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
  :hover {
    background: #0064b8;
  }
  &:disabled {
    background: #ddd;
    color: #333;
    cursor: default;
  }
`;

const LightButton = styled.button`
  cursor: pointer;
  font-weight: 700;
  color: #444;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  padding: 10px 0;
  background: transparent;
  :hover {
    color: black;
  }
`;

const ErrorMessage = styled.div`
  color: hsl(5, 95%, 30%);
  background: hsl(5, 95%, 92%);
  padding: 12px 16px;
  font-weight: 700;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  margin: 0 10px;
`;

function Cart(props) {
  let [page, setPage] = useState(0);
  let [error, setError] = useState();
  let [working, setWorking] = useState(false);
  let [address, setAddress] = useState({
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  let [taxAmount, setTaxAmount] = useState();
  let [shippingMethod, setShippingMethod] = useState();
  let [shippingMethods, setShippingMethods] = useState([]);
  let [email, setEmail] = useState("");
  let [name, setName] = useState("");
  let [cart, setCart] = useGlobal("cart");
  let [baseData] = useGlobal("baseData");

  Cart.handleClickOutside = props.close;

  function pageToTitle(page) {
    if (page === 0) return "Your Cart";
    if (page === 1) return "Shipping Details";
    if (page === 2) return "Shipping Method";
    if (page === 3) return "Sales Tax";
    if (page === 4) return "Check out";
    if (page === 5) return "Order confirmation";
  }

  function pageToButton(page) {
    if (page === 0) return "Check out";
    if (page === 1 || page === 2 || page === 3) return "Continue";
    if (page === 4)
      return (
        "Pay $" +
        getTotal(
          cart,
          baseData,
          shippingMethods.filter((i) => i.id === shippingMethod)[0].rate,
          taxAmount ? taxAmount : undefined
        )
      );
    if (page === 5) return "Close";
  }

  async function nextStep() {
    if (page === 0) setPage(1);
    if (page === 1) {
      if (
        !address.country ||
        !address.street1 ||
        (["United States", "Canada", "Australia"].includes(address.country) &&
          !address.state)
      )
        return setError("Address information required");
      setWorking(true);
      // Get shipping options
      const data = await (
        await fetch(apiRoot + "/shipping", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: {
              ...address,
              country: baseData.countries.filter(
                (i) => i.name === address.country
              )[0].code,
              state:
                ["United States", "Canada", "Australia"].includes(
                  address.country
                ) && address.state
                  ? baseData.countries
                      .filter((i) => i.name === address.country)[0]
                      .states.filter((i) => i.name === address.state)[0].code
                  : null,
            },
            items: cart,
          }),
        })
      ).json();
      setWorking(false);
      if (!data.success)
        return setError(
          data.message || "There was an error getting shipping options."
        );
      setError(null);
      setShippingMethods(data.result);
      setPage(2);
      setWorking(true);
    }
    if (page === 2) {
      setWorking(true);
      const data = await (
        await fetch(apiRoot + "/tax", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: {
              ...address,
              country: baseData.countries.filter(
                (i) => i.name === address.country
              )[0].code,
              state:
                ["United States", "Canada", "Australia"].includes(
                  address.country
                ) && address.state
                  ? baseData.countries
                      .filter((i) => i.name === address.country)[0]
                      .states.filter((i) => i.name === address.state)[0].code
                  : null,
            },
            items: cart,
            shippingMethod,
          }),
        })
      ).json();
      setWorking(false);
      if (!data.success)
        return setError(
          data.message || "There was an error getting tax information."
        );
      if (data.taxAmount) setTaxAmount(data.taxAmount);
      setPage(data.taxAmount ? 3 : 4);
      if (!data.taxAmount) setWorking(true);
    }
    if (page === 3) {
      setPage(4);
      setWorking(true);
    }
    if (page === 4) {
      const { token, error } = await props.stripe.createToken({ name });
      if (error) return setError(error.message);
      setError(null);
      setWorking(true);
      // Submit order
      let total = getTotal(
        cart,
        baseData,
        shippingMethods.filter((i) => i.id === shippingMethod)[0].rate,
        taxAmount ? taxAmount : undefined
      );
      const data = await (
        await fetch(apiRoot + "/order", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: {
              ...address,
              country: baseData.countries.filter(
                (i) => i.name === address.country
              )[0].code,
              state:
                ["United States", "Canada", "Australia"].includes(
                  address.country
                ) && address.state
                  ? baseData.countries
                      .filter((i) => i.name === address.country)[0]
                      .states.filter((i) => i.name === address.state)[0].code
                  : null,
            },
            items: cart,
            shippingMethod,
            name,
            email,
            total,
            taxAmount,
            payment: {
              method: "stripe",
              token: token.id,
            },
          }),
        })
      ).json();
      setWorking(false);
      if (!data.success)
        return setError(
          data.message || "There was an error placing your order."
        );
      setCart([]);
      setPage(5);
    }
    if (page === 5) {
      props.close();
    }
  }

  async function handlePaypal(name, email, orderID) {
    setError(null);
    setWorking(true);
    // Submit order
    let total = getTotal(
      cart,
      baseData,
      shippingMethods.filter((i) => i.id === shippingMethod)[0].rate,
      taxAmount ? taxAmount : undefined
    );
    const data = await (
      await fetch(apiRoot + "/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address: {
            ...address,
            country: baseData.countries.filter(
              (i) => i.name === address.country
            )[0].code,
            state:
              ["United States", "Canada", "Australia"].includes(
                address.country
              ) && address.state
                ? baseData.countries
                    .filter((i) => i.name === address.country)[0]
                    .states.filter((i) => i.name === address.state)[0].code
                : null,
          },
          items: cart,
          shippingMethod,
          name,
          email,
          total,
          taxAmount,
          payment: {
            method: "paypal",
            token: orderID,
          },
        }),
      })
    ).json();
    setWorking(false);
    if (!data.success)
      return setError(data.message || "There was an error placing your order.");
    setCart([]);
    setPage(5);
  }

  return (
    <MainBox>
      <Header>
        <Title>{pageToTitle(page)}</Title>
        <Close onClick={props.close}>
          <svg height="36" width="36" viewBox="0 0 100 100">
            <g>
              <g fill="#000000">
                <polygon points="50 47 71 26 74 29 53 50 74 71 71 74 50 53 29 74 26 71 47 50 26 29 29 26"></polygon>
              </g>
              <g fill="#000000">
                <polygon points="50 47 71 26 74 29 53 50 74 71 71 74 50 53 29 74 26 71 47 50 26 29 29 26"></polygon>
              </g>
            </g>
          </svg>
        </Close>
      </Header>
      <Page visible={page === 1}>
        {page === 0 && <ProductList />}
        {page === 1 && (
          <ShippingAddress address={address} setAddress={setAddress} />
        )}
        {page === 2 && (
          <ShippingMethod
            shippingMethods={shippingMethods}
            shippingMethod={shippingMethod}
            setShippingMethod={(x) => {
              setShippingMethod(x);
              setWorking(false);
            }}
          />
        )}
        {page === 3 && (
          <SalesTax
            taxAmount={taxAmount}
            shippingMethodPrice={
              shippingMethods.filter((i) => i.id === shippingMethod)[0].rate
            }
          />
        )}
        {page === 4 && (
          <Checkout
            selectStripe={() => setWorking(false)}
            email={email}
            setEmail={setEmail}
            name={name}
            setName={setName}
            total={getTotal(
              cart,
              baseData,
              shippingMethods.filter((i) => i.id === shippingMethod)[0].rate,
              taxAmount ? taxAmount : undefined
            )}
            handlePaypal={handlePaypal}
            address={address}
            countries={baseData.countries}
          />
        )}
        {page === 5 && <Complete />}
      </Page>
      <Footer>
        {page !== 5 ? (
          <LightButton
            onClick={() => {
              page === 0
                ? props.close()
                : setPage(page === 4 ? (taxAmount ? 3 : 2) : page - 1);
              setWorking(false);
              setError(null);
            }}
          >
            {page === 0 ? "Continue shopping" : "Back"}
          </LightButton>
        ) : (
          <div />
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {(cart.length > 0 || page === 5) && (
          <Button onClick={nextStep} disabled={working}>
            {pageToButton(page)}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill={working ? "#333" : "white"}
                d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
              />
            </svg>
          </Button>
        )}
      </Footer>
    </MainBox>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Cart.handleClickOutside,
};

const ClickOutsideCart = onClickOutside(injectStripe(Cart), clickOutsideConfig);

function Overlay(props) {
  return (
    <Background>
      <ClickOutsideCart close={props.close} />
    </Background>
  );
}

export default Overlay;
